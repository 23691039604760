import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { addBeaconNumberPrefix, AddFacilityPersonRequest, Beacon, Facility, Simulator, UpdateSettingsRequest, Wrangler } from '../models/facility.model';
import { Topic } from '@weavix/models/src/topic/topic';

import { GeofenceService } from './geofence.service';
import { CacheContext, HttpService } from './http.service';
import { ItemService } from './item.service';
import { PubSubService } from './pub-sub.service';
import { TranslationService } from './translation.service';
import { SiteSurveyData, SiteSurveyFilter } from '@weavix/models/src/facility/site-survey';

@Injectable({
    providedIn: 'root',
})
export class FacilityService {
    constructor(
        private httpService: HttpService,
        private pubSubService: PubSubService,
        private translationService: TranslationService,
        private router: Router,
    ) {
        this.currentFacility$.subscribe(f => {
            this.facilityId = f?.id;
        });
    }

    static baseUrl = '/track/facilities';
    static facilityPeopleUrl = '/core/facility-people';
    static cacheCollection = 'facilities';

    facilityId: string;
    public currentFacility$: BehaviorSubject<Facility> = new BehaviorSubject(undefined);
    private readonly cacheContext: CacheContext = { collection: FacilityService.cacheCollection, maxAge: 1800000 };

    public clearCache = () => HttpService.clearCache(this.cacheContext.collection);

    static url = (id?: string) => id ? `${FacilityService.baseUrl}/${id}` : FacilityService.baseUrl;

    getCurrentFacility() {
        return this.currentFacility$.value;
    }

    public getCurrentFacility$(): Observable<Facility> {
        return this.currentFacility$.asObservable();
    }

    public getCurrentFacilityId$(): Observable<string> {
        return this.getCurrentFacility$().pipe(map((facility) => facility?.id));
    }

    public getLastFacilityIdFromStorage(): string | null {
        return localStorage.getItem('navigation-facility');
    }

    getCurrentFacilityId() {
        return this.getCurrentFacility()?.id;
    }

    setCurrentFacility(facility: Facility) {
        localStorage.setItem('navigation-facility', facility?.id);
        this.currentFacility$.next(facility);
    }

    async getWithAccountId(component: any, id: string, accountId: string) {
        return this.httpService.get<Facility>(component, `/a/${accountId}${FacilityService.url(id)}`);
    }

    /**
     * Make sure current facility is set and redirect to /facilities if not
     */
    async validateCurrentFacility(): Promise<boolean> {
        const fac = await this.getCurrentFacility();
        if (!fac || !fac.id) {
            this.router.navigateByUrl('/settings/facilities');
            return false;
        }
        return true;
    }

    async validateRouteFacility(id: string) {
        try {
            const facility = await this.httpService.get<Facility>(null, FacilityService.url(id), null, this.cacheContext);
            if (facility) {
                this.setCurrentFacility(facility);
                return Promise.resolve(true);
            }
            return Promise.resolve(false);
        } catch (e) {
            console.error(`Could not access facility: ${id}`, e);
            return Promise.resolve(false);
        }
    }

    //Gets all facilities for the current user context, meaning the account the user is accessing
    getMyFacilitiesOnCurrentAccount(component: any) {
        return this.httpService.get<Facility[]>(component, `${FacilityService.url()}/mine`, {}, this.cacheContext);
    }

    //Gets all facilities for the current user across any account
    getAllMyFacilitiesOnAnyAccount(component: any) {
        return this.httpService.get<Facility[]>(component, '/core/me/facilities', {}, this.cacheContext);
    }

    getAll(component: any, tags?: string[], clearCache: boolean = false): Promise<Facility[]> {
        if (clearCache) HttpService.clearCache(this.cacheContext.collection);
        return this.httpService.get<Facility[]>(component, `${FacilityService.url()}`, { tags }, this.cacheContext);
    }

    getSimulator(component: any, facilityId: string) {
        return this.httpService.get<Simulator>(component, `/simulator/${facilityId}`);
    }

    updateSimulator(component: any, facilityId: string, simulator: Simulator) {
        return this.httpService.post<Simulator>(component, `/simulator/${facilityId}`, simulator);
    }

    get(component: any, id: string, cache: boolean = false) {
        return this.httpService.get<Facility>(component, FacilityService.url(id), null, cache ? this.cacheContext : null);
    }

    add(component: any, facility: Facility) {
        return this.httpService.post<Facility>(component, FacilityService.url(), facility, this.cacheContext);
    }

    addPerson(component: any, request: AddFacilityPersonRequest) {
        HttpService.clearCache(this.cacheContext.collection);
        return this.httpService.post<Facility>(component, `${FacilityService.facilityPeopleUrl}/person`, request);
    }

    removePerson(component: any, facilityId: string, personId: string) {
        HttpService.clearCache(this.cacheContext.collection);
        return this.httpService.delete<Facility>(component, `${FacilityService.facilityPeopleUrl}/${facilityId}/person/${personId}`);
    }

    update(component: any, id: string, facility: Facility) {
        HttpService.clearCache(this.cacheContext.collection);
        return this.httpService.put<Facility>(component, FacilityService.url(id), facility, this.cacheContext);
    }

    updateSettings(component: any, id: string, settings: UpdateSettingsRequest) {
        HttpService.clearCache(this.cacheContext.collection);
        return this.httpService.put<UpdateSettingsRequest>(component, `${FacilityService.url(id)}/settings`, { settings });
    }

    delete(component: any, id: string) {
        GeofenceService.clearCache();
        ItemService.clearItemCache();
        return this.httpService.delete<void>(component, FacilityService.url(id), null, this.cacheContext);
    }

    async subscribeFacilityUpdates(c: any, accountId: string, facilityId: string) {
        return this.pubSubService.subscribe<any>(c, Topic.AccountMapFacilities, [accountId, facilityId || '+']);
    }

    getBadges(component: any, facilityId: string) {
        return this.httpService.get<any[]>(component, `${FacilityService.facilityPeopleUrl}/${facilityId}/badges`);
    }

    async getWeather(component: any, id: string, language: string = this.translationService.getLocale()): Promise<{ icon: string, temperature: string }> {
        return this.httpService.get<{ icon: string, temperature: string }>(component, `${FacilityService.url(id)}/weather`, { language });
    }

    upsertBeacon(component: any, id: string, beacon: Beacon) {
        const update = { ...beacon, id: addBeaconNumberPrefix(beacon.id) };
        return this.httpService.post<Beacon>(component, `${FacilityService.url(id)}/beacons`, update, this.cacheContext);
    }

    removeBeacon(component: any, id: string, beaconId: string) {
        beaconId = addBeaconNumberPrefix(beaconId);
        return this.httpService.delete<void>(component, `${FacilityService.url(id)}/beacons/${beaconId}`);
    }

    upsertWrangler(component: any, id: string, wrangler: Wrangler) {
        const update = { ...wrangler, id: { wranglerId: wrangler.id, facilityId: id } };
        return this.httpService.post<Wrangler>(component, `${FacilityService.url(id)}/wrangler`, update, this.cacheContext);
    }

    removeWrangler(component: any, id: string, wranglerId: string) {
        return this.httpService.delete<Wrangler>(component, `${FacilityService.url(id)}/wrangler/${wranglerId}`);
    }

    getFacilitySurveys(facilityId:string, filter: SiteSurveyFilter){
        return this.httpService.post<SiteSurveyData[]>(null, `${FacilityService.url(facilityId)}/site-surveys/query`, filter);
    }

    processFacilitySurveys(facilityId:string, filter: SiteSurveyFilter){
        return this.httpService.post(null, `${FacilityService.url(facilityId)}/site-surveys/calculate`, filter);
    }
}
