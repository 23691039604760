import { ComplexPersonCondition, ConditionalKeys, PersonFacilityPredicate, PersonLike, PersonMatcher, SimplePersonCondition } from '@weavix/models/src/person/person-matcher';
import { isEmpty, isNil } from 'lodash';

function matchesAtLeastOne(list: string[] | undefined, values: (string | undefined)[] | undefined): boolean {
    if (!list) return false;
    if (!values) return false;
    return list.some(listValue => values.includes(listValue));
}

function standardMatch(matcher: PersonMatcher, person: PersonLike) {
    if (matchesAtLeastOne(matcher.excludedPeople, [person.id]) ||
        matchesAtLeastOne(matcher.excludedTags, person.tags) ||
        matchesAtLeastOne(matcher.excludedCrafts, person.crafts) ||
        matchesAtLeastOne(matcher.excludedCompanies, [person.companyId]) ||
        (!isEmpty(matcher.peopleCrafts) && !matchesAtLeastOne(matcher.peopleCrafts, person.crafts)) ||
        (!isEmpty(matcher.peopleTags) && !matchesAtLeastOne(matcher.peopleTags, person.tags)) ||
        (!isEmpty(matcher.companies) && !matchesAtLeastOne(matcher.companies, [person.companyId]))
    ) {
        return false;
    }
    return true;
}

function advancedMatch(condition: ComplexPersonCondition, person: PersonLike, isPersonOnFacility: PersonFacilityPredicate, def: boolean): boolean {
    const enabledConditions = condition.conditions.filter(v => v.enabled !== false);
    if (!enabledConditions.length) return def;
    return enabledConditions[condition.type === 'or' ? 'some' : 'every'](v => {
        if ((v as SimplePersonCondition).key) {
            return evaluateCondition(v as SimplePersonCondition, person, isPersonOnFacility);
        } else {
            const defaultReturn = condition.type === 'and';
            return advancedMatch(v as ComplexPersonCondition, person, isPersonOnFacility, defaultReturn);
        }  
    });
}

export function evaluateCondition(condition: SimplePersonCondition, person: PersonLike, facilityLookup: PersonFacilityPredicate) {
    const doStringEvaluation = (values: string[], includedValue: string | undefined, negate: boolean | undefined) => {
        if (!isNil(includedValue) && values.includes(includedValue)) {
            if (!negate) return true;
            else return false;
        } else {
            if (negate) return true;
            else return false;
        }
    };

    const doArrayEvaluation = (values: string[], includedArray: string[] | undefined, negate: boolean | undefined) => {
        if (values.some(x => (includedArray ?? []).includes(x))) {
            if (!negate) return true;
            else return false;
        } else {
            if (negate) return true;
            else return false;
        }
    };

    if (condition.key === ConditionalKeys.People) return doStringEvaluation(condition.value, person.id, condition.negate);
    if (condition.key === ConditionalKeys.PersonCompany) return doStringEvaluation(condition.value, person.companyId, condition.negate);
    if (condition.key === ConditionalKeys.PersonCraft) return doArrayEvaluation(condition.value, person.crafts, condition.negate);
    if (condition.key === ConditionalKeys.PersonSites) {
        if (person.group === 'global-admin') {
            if (!condition.negate) return true;
            else return false;
        } else {
            const match = condition.value.some((x: string) => facilityLookup(x, person));
            return condition.negate ? !match : match;
        }
    }
    if (condition.key === ConditionalKeys.PersonTags) return doArrayEvaluation(condition.value, person.tags, condition.negate);
    return false;
}

export function matchPerson(matcher: PersonMatcher, person: PersonLike, isPersonOnFacility: PersonFacilityPredicate) {
    // person still exists but userId removed when Person is removed from account
    // Temporary support users should not be auto-added to channels.
    if (!person?.userId || person.supportAccess) return false; 

    if (person.group !== 'global-admin') {
        if (matcher.facilityIds && !isEmpty(matcher.facilityIds) && !matcher.facilityIds.some(facilityId => isPersonOnFacility(facilityId, person))) {
            return false;
        }
        if (matcher.excludedFacilityIds && !isEmpty(matcher.excludedFacilityIds) && matcher.excludedFacilityIds.some(facilityId => isPersonOnFacility(facilityId, person))) {
            return false;
        }
    }

    if (matcher.personCondition?.enabled) {
        return advancedMatch(matcher.personCondition, person, isPersonOnFacility, true);
    }

    if (!isEmpty(matcher.people)) {
        const personInList = matcher.peopleSet ? matcher.peopleSet.has(person.id!) : matcher.people?.includes(person.id!);
        if (person.id && personInList && !matchesAtLeastOne(matcher.excludedPeople, [person.id])) return true;
        if (isEmpty(matcher.facilityIds) && isEmpty(matcher.peopleCrafts) && isEmpty(matcher.peopleTags) && isEmpty(matcher.companies)) return false;
    }

    return standardMatch(matcher, person);
}
