export enum ConditionalKeys {
    People = 'people',
    PersonCompany = 'personCompany',
    PersonCraft = 'personCrafts',
    PersonSites = 'personSites',
    PersonTags = 'personTags',
}

export interface SimplePersonCondition {
    key?: ConditionalKeys;
    value: string[];
    negate?: boolean;
    enabled?: boolean;
}

export interface ComplexPersonCondition {
    conditions: PersonCondition[];
    type: 'or' | 'and';
    enabled?: boolean;
}

export type PersonCondition = ComplexPersonCondition | SimplePersonCondition;

// simplified interface required for person matching
export interface PersonLike {
    id?: string;
    userId?: string;
    group?: string;
    crafts?: string[];
    tags?: string[];
    companyId?: string;
    supportAccess?: string;
}

export interface PersonMatcher {
    people?: string[];
    peopleSet?: Set<string>; // optimization to check for person in long list
    peopleCrafts?: string[];
    peopleTags?: string[];
    companies?: string[];
    facilityIds?: string[];
    excludedTags?: string[];
    excludedPeople?: string[];
    excludedCompanies?: string[];
    excludedCrafts?: string[];
    excludedFacilityIds?: string[];

    personCondition?: ComplexPersonCondition;
}

export type PersonFacilityPredicate = (facilityId: string, person?: PersonLike) => boolean;

export const StandardConditions = {
    people: { key: ConditionalKeys.People, negate: false },
    peopleTags: { key: ConditionalKeys.PersonTags, negate: false },
    peopleCrafts: { key: ConditionalKeys.PersonCraft, negate: false },
    companies: { key: ConditionalKeys.PersonCompany, negate: false },
    excludedPeople: { key: ConditionalKeys.People, negate: true },
    excludedTags: { key: ConditionalKeys.PersonTags, negate: true },
    excludedCrafts: { key: ConditionalKeys.PersonCraft, negate: true },
    excludedCompanies: { key: ConditionalKeys.PersonCompany, negate: true },
    facilityIds: { key: ConditionalKeys.PersonSites, negate: false },
    excludedFacilityIds: { key: ConditionalKeys.PersonSites, negate: true },
};
